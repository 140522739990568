import React from 'react';
import { RichTextEditorProps } from './RichTextEditor.types';
import { Editor } from '@tinymce/tinymce-react';

import './RichTextEditor.scss';

/**
 * Secondary description on Storybook Docs. I can have multiple lines and
   bullets!
  - The primary component has:
    - CSS modules
    - Tailwind styles
    - CSS variables
  - To make sure all different CSS channels are working
  */
const RichTextEditor: React.FC<RichTextEditorProps> = ({
  className = '',
  initialValue = '',
  placeholder = '',
  height = '340',
  tinymceApiKey,
  tinymceConfig,
  onChange,
  props,
}) => {
  return (
    <div data-testid='rich-text-editor-component' className={`rich-text-editor__container ${className}`}>
      <Editor
        apiKey={tinymceApiKey}
        onChange={(e: any) => onChange(e)}
        initialValue={initialValue}
        init={{
          menubar: false,
          resize: false,
          preview_styles: false,
          relative_urls: false,
          convert_urls: false,
          height: height,
          statusbar: false,
          plugins: 'bullist link lists textpattern',
          contextmenu: 'bullist link',
          toolbar_mode: 'wrap',
          toolbar: 'bullist link | bold italic underline',
          placeholder: placeholder,
          ...tinymceConfig,
        }}
        {...props}
      />
    </div>
  );
};

export default RichTextEditor;
