import React, { useEffect } from 'react';
import classes from './Toast.module.scss';
import { ToastProps } from './Toast.types';
import IconButton from '../button/IconButton/IconButton';
import Icon from '../Icon/Icon';
import TextLink from '../TextLink/TextLink';

/**
 * Use inline styles to adjust positioning for your use case: (ie. `style={{top: '10px', right: '10px'}}`)
  - The primary component has:
    - CSS modules
    - Tailwind styles
    - CSS variables
  - use `inline` prop for full width.
  */
const Toast: React.FC<ToastProps> = ({
  type = 'info', // Set a default
  closeButton,
  inline = false,
  children,
  className = '',
  autoCloseTime,
  actionLink,
  ...props
}) => {
  const [show, setShow] = React.useState(true);
  const [_animatingOut, _setAnimatingOut] = React.useState(false);

  useEffect(() => {
    if (autoCloseTime) {
      setTimeout(() => {
        _setAnimatingOut(true);
      }, autoCloseTime);
    }
  }, [autoCloseTime]);

  let icon = '';

  if (type === 'warning') {
    icon = 'warning';
  } else if (type === 'info') {
    icon = 'info';
  } else if (type === 'negative') {
    icon = 'report';
  } else if (type === 'success') {
    icon = 'check_circle';
  }

  return show ? (
    <div
      data-testid='toast-component'
      className={[
        classes['Toast'],
        classes[type],
        className,
        inline ? classes['Toast--Inline'] : '',
        `${!_animatingOut ? classes[`Toast--fade-in`] : classes[`Toast--fade-out`]}`,
      ].join(' ')}
      {...props}
      onAnimationEnd={(e) => {
        if (_animatingOut) {
          setShow(false);
          _setAnimatingOut(false);
        }
      }}
    >
      <div className={classes['Toast--Icon']}>
        <Icon name={icon} />
      </div>
      <div className='sui-shrink-0 sui-body sui-font-bold sui-mr-4'>
        {children}
        {actionLink && (
          <TextLink href={actionLink.href} className='sui-ml-2' onClick={actionLink.onClick} variantType='secondary'>
            {actionLink.label}
          </TextLink>
        )}
      </div>
      {closeButton && <IconButton icon='close' onClick={() => _setAnimatingOut(true)} sentiment='neutral' />}
    </div>
  ) : null;
};

export default Toast;
