import React from 'react';
import classes from './TextLink.module.scss';
import { TextLinkProps } from './TextLink.types';

const TextLink: React.FC<TextLinkProps> = ({
  variantType = 'primary', // Set a default
  disabled = false,
  href,
  children,
  sentiment,
  className = '',
  ...props
}) => {
  const linkProps = {
    'data-testid': 'text-link-component',
    ...props,
    disabled,
  };

  const buttonProps = {
    'data-testid': 'text-link-component',
    ...props,
    disabled,
  };

  return (
    <>
      {href ? (
        <a
          className={[
            classes['TextLink'],
            classes[`type-${variantType}`],
            classes[`sentiment-${sentiment}`],
            disabled ? classes['grayed'] : '',
            className,
          ].join(' ')}
          {...(linkProps as React.AnchorHTMLAttributes<HTMLAnchorElement>)}
          href={href}
        >
          {children}
        </a>
      ) : (
        <button
          type='button'
          onClick={props.onClick}
          className={[
            classes['TextLink'],
            classes[`type-${variantType}`],
            classes[`sentiment-${sentiment}`],
            disabled ? classes['grayed'] : '',
            className,
          ].join(' ')}
          {...(buttonProps as React.ButtonHTMLAttributes<HTMLButtonElement>)}
        >
          {children}
        </button>
      )}
    </>
  );
};

export default TextLink;
